/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui"
import shield from "../images/shield.svg"


export default (props) => (
    <div
      sx={{
        bg: "text",
        py: [3, 4],
        px: [3, 5],
        maxWidth: "col12",
        mx: "auto",
      }}
    >
      <div
        sx={{
          maxWidth: "col10",
          mx: "auto",
          color: "white",
          px: [0, 0, 2, 5],
          position: [null,"relative"],
          display: ['flex', 'block'],
          flexDirection: ['row', null],
          alignItems: 'center'
        }}
      >
        <div>
        <h2 sx={{ fontSize: 3 }}>
          Cubeless&trade;
        </h2>
        <h1
          sx={{
            fontSize: [4,5],
            fontFamily: "heading",
            lineHeight: 0.9,
          }}
        >
          The Security Platform
          <br />
          that Powers ModSquad
        </h1>
        </div>
        <div sx={{ 
            width: [null, 120, 240], 
            position: [ null,"absolute"], 
            top: [null, -44, -57], 
            right: [null, -48, -50, 5] }}>
          <img sx={{ heigt: "100%", width: "100%" }} src={shield} alt="Cubeless Security Shield" />
        </div>
      </div>
    </div>
)
