/** @jsx jsx */
import { jsx, Link as A, BaseStyles } from "theme-ui";
// import * as React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import Hero from "../components/hero";
import Platform from "../components/platform";
import Contain from "../components/contain"
import Stack from "../components/stack"
import CTA from "../components/cta"
import SEO from "../components/seo"
import PageTitle from "../components/pageTitle"
// import Btn from "../components/button"

// markup
const IndexPage = () => {
  return (
    <Layout>
      <Contain mw={'col10'}>
        <SEO  />
        <PageTitle name="The Platform" />
      </Contain>
      <Hero />
      
      <div sx={{
        mt: 5,
        mb: 4,
        textAlign: 'center'
        }}>
        <Contain mw={'col10'}>
          <h2 sx={{ 
            fontSize: 5, 
            fontFamily: "heading", 
            lineHeight: 0.9,
            }}>
              Modular. Custom. Compliant.
          </h2>
          <h3 sx={{ 
            mt: [3,1],
            fontSize: 3, 
            fontFamily: "heading", 
            lineHeight: 0.9,
            }}>
              Get the exact level of security your project requires.
            </h3>
          </Contain>
      </div>
        
        <Contain mw={'col6'}>
          <BaseStyles>            
              <Stack gap={3}>
                <p>
                  Services such as <strong>customer support</strong>, <strong>moderation</strong>, <strong>social media</strong> and <strong>community management</strong> all demand protection of your customers’ data, as well as the communities that your business has worked so hard to curate. Cubeless from ModSquad is a flexible and modular approach, customized to your data security and privacy needs and created so that you always have the right level of protection your business requires.
                </p>

                <p>
                  Cubeless is security without borders. It’s insurance for business continuity. It’s customer experience freedom, so you can focus on the bottom line while we focus on compliance and secure customer experience interactions. 
                </p>

                <p>
                  Cubeless is the result of our experience securing a remote workforce over the last decade. We just put a name to it, trademarked it, and have a <b>patent pending</b>.
                </p>

                <p>
                  Here’s how it works.
                </p>
              </Stack>
          </BaseStyles>
        </Contain>
  
        
        <Platform/>

        <Contain mw={'col6'}>
          <BaseStyles>                    
            <Stack gap={3}> 

                <p>
                  Cubeless seamlessly integrates with any software and system that your business already uses, ensuring that our team becomes a secure extension of your team. Keep your CX workflows fast and frictionless. Behind the scenes, we’re continually innovating to keep Cubeless on the leading edge so your security remains current in a constantly evolving world of threats.  We’ve taken the guesswork out of keeping your data secure.
                </p>

                <p>
                  Why Cubeless from ModSquad? Well, we went on a bit of a rant about that. Read <Link sx={{ color: "hotRed" }} to="/manifesto">The Manifesto</Link>.
                </p>

            </Stack>
          </BaseStyles>
          <div sx={{ mt: 4 }}>
             <CTA>
              Let’s talk about how ModSquad can secure all things CX.
             </CTA>
          </div>

      </Contain>
    </Layout>
  );
};

export default IndexPage
