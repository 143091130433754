/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui";

export default (props) => (
  <svg
    {...props}
    width={props.width}
    // width="407"
    // height="410"
    viewBox="0 0 407 410"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {/* <path d="M0 43.6892C0 43.6892 179.963 -54.6115 406.888 43.6892L403.54 137.491C403.54 137.491 408.897 350.954 203.453 409.506C203.453 409.506 16.02 381.758 2.78161 137.749L0 43.6892Z" fill={props.outline} /> */}
    <path d="M0 43.6892C0 43.6892 179.963 -54.6115 406.888 43.6892L403.54 137.491C403.54 137.491 408.897 350.954 203.453 409.506C203.453 409.506 16.02 381.758 2.78161 137.749L0 43.6892Z" sx={{fill: props.outline }} />
    <path d="M17.7715 57.7688C17.7715 57.7688 182.007 -31.9467 389.099 57.7688L386.043 143.381C386.043 143.381 390.937 338.179 203.436 391.631C203.436 391.631 32.3835 366.322 20.2955 143.621L17.7715 57.7688Z" sx={{fill: props.shieldbg}} />
    <path d="M306.374 190.273C306.374 247.897 260.7 293.158 202.561 293.141C146.568 293.124 100.586 246.884 100.5 190.514C100.414 132.924 146.586 86.993 204.811 87.3364C262.812 87.6798 307.249 136.049 306.374 190.273ZM121.465 190.084C121.465 235.449 158.055 272.348 203.3 272.571C248.029 272.794 285.924 235.449 285.804 191.286C285.683 144.875 249.385 108.147 203.677 108.164C158.158 108.164 121.465 144.737 121.465 190.084Z" sx={{fill: props.print}} />
    <path d="M312.709 278.409C281.356 318.124 223.972 341.184 165.747 324.409C110.561 308.509 68.236 260.878 65.0595 197.691C61.5224 126.725 106.801 76.313 158.707 58.3012C214.683 38.8815 275.363 57.0821 310.854 99.8709C310.374 100.867 309.446 100.489 308.708 100.489C299.934 100.592 291.16 100.592 282.386 100.764C280.274 100.798 278.711 100.128 277.08 98.8407C257.248 83.2499 234.772 74.6132 209.497 73.686C150.689 71.5053 100.619 111.564 89.6132 169.428C77.5596 232.873 122.237 296.129 185.922 305.607C220.692 310.775 251.805 302.259 279.141 280.126C280.703 278.873 282.214 278.358 284.172 278.375C293.529 278.444 302.904 278.409 312.709 278.409Z" sx={{fill: props.print}} />
    <path d="M310.684 147.296C318.616 147.296 326.034 147.364 333.469 147.244C335.495 147.21 335.77 148.395 336.199 149.82C340.972 165.359 342.655 181.259 341.779 197.468C341.178 208.508 339.393 219.36 336.731 230.092C336.062 232.77 334.688 233.491 332.215 233.457C325.416 233.371 318.599 233.423 311.405 233.423C322.016 204.782 321.93 176.228 310.684 147.296Z" sx={{fill: props.print}} />
    <path d="M203.624 120.956C242.79 121.059 274.332 153.649 272.632 193.33C271.07 229.731 241.193 260.02 201.753 259.522C162.999 259.024 133.311 226.28 134.187 187.904C135.045 151.107 166.313 120.561 203.624 120.956ZM203.504 238.677C230.015 238.677 252.01 216.681 252.062 190.119C252.113 163.762 229.929 141.801 203.281 141.801C176.478 141.801 154.894 163.59 154.894 190.685C154.877 217.008 176.838 238.677 203.504 238.677Z" sx={{fill: props.print}} />
    <path d="M203.542 154.095C223.597 154.147 239.806 170.442 239.668 190.394C239.531 210.449 223.356 226.434 203.233 226.4C183.177 226.366 167.106 210.191 167.192 190.085C167.278 169.995 183.383 154.044 203.542 154.095Z" sx={{fill: props.print}} />
  </svg>
)
