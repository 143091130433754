/** @jsxRuntime classic */
/** @jsx jsx */
import { Link } from "gatsby"
import { jsx, Box, Themed, Link as A } from "theme-ui"
import Stack from "./stack"
import Shield from "./shield"
import ThicLine from './editorial-thicline'

const PlatformH2 = ({ children }) => (
  <h2
    sx={{
      fontSize: 5,
      fontFamily: "sans",
      fontWeight: "900",
      lineHeight: .8,
      mt: 2,
    }}
  >
    {children}
  </h2>
)

const PlatformH3 = ({ children }) => (
  <h3
    sx={{
      fontSize: 4,
      fontFamily: "sans",
      fontWeight: "900",
      textTransform: "uppercase",
      lineHeight: 0.9,
    }}
  >
    {children}
  </h3>
)

const PlatformH4 = ({ children }) => (
  <h4
    sx={{
      fontSize: 2,
      fontFamily: "sans",
      fontWeight: "bold",
      lineHeight: 1,
    }}
  >
    {children}
  </h4>
)

const Eyebrow = ({ children }) => (
  <p
    sx={{
      fontSize: 1,
      fontStyle: "italic",
    }}
  >
    {children}
  </p>
)

const SmallShield = () => (
  <div sx={{
    width: [72,100],
    display: 'inline-block',
  }}>
  <Shield width={'100%'} shieldbg="almond" print="text" outline="text" />
  </div>
)

const ShieldCol = ({ children }) => (
  <div
    sx={{
      width: [null, null, 'col2'],
      textAlign: ['center', 'center', 'right'],
      pr: [0,3],
      mr: [0,3],
    }}
  >
    {children}
  </div>
)

const CopyCol = ({ children }) => (
  <div
    sx={{
      maxWidth: "col6",
      mx: ['auto', 'auto', 0]
    }}
  >
    {children}
  </div>
)

const SecStandard = ({ children }) => (
  <div
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    }}
  >
    <Shield width={40} shieldbg="hotRed" print="white" outline="hotRed" />
    <PlatformH4>{children}</PlatformH4>
  </div>
)

const SecSection = ({ children }) => (
  <div
    sx={{
      display: ['block', 'block', 'flex'],
    }}
  >
    <ShieldCol>
      <SmallShield />
    </ShieldCol>
    <CopyCol>{children}</CopyCol>
  </div>
)

export default (props) => (
  <div sx={{ 
    maxWidth: 'col10', 
    mx: 'auto',
    mt: 5 
  }}>

    <ThicLine />
    
    <Stack gap={4}>
    
      <PlatformH2>The Cubeless Platform</PlatformH2>
    
      {/* Introduction */}
      <div sx={{
         display: ['block', 'flex']}
      }>
        <ShieldCol>&nbsp;</ShieldCol>
        <CopyCol>
              <p sx={{ fontSize: 2, fontStyle: "italic" }}>The Cubeless platform is ModSquad’s proprietary mix of standards, compliant software, and systems. This platform is reinforced with comprehensive policies, training, and education for all our CX experts &mdash; The Mods.</p>
        </CopyCol>
      </div>

      {/* Baseline  */}
      <SecSection>
        <Box mb={3}>
          <Stack gap={2}>
            <Stack gap={1}>
              <Eyebrow>Industry Leading</Eyebrow>
              <PlatformH3>Baseline Security</PlatformH3>
            </Stack>
            <Themed.p>For every project, we secure every node in the CX pipeline from the Mod to the cloud and back.</Themed.p>
          </Stack>
        </Box>

        <Stack gap="3">
          <Stack gap="1">
            <PlatformH4>Mods</PlatformH4>
            <Themed.p>Every Mod is background checked. Onboarding includes security awareness orientation and technical overview.</Themed.p>
          </Stack>

          <Stack gap="1">
            <PlatformH4>Device / Endpoint</PlatformH4>
            <Themed.p>Every endpoint has anti-virus software, firewalls, and required software patches prescribed and verified by our security team every time the Mod logs into the system.</Themed.p>
          </Stack>

          <Stack gap="1">
            <PlatformH4>Connection &amp; Cloud Access</PlatformH4>
            <Themed.p>Access control is multi-layered including Single Sign On (SSO), Multi Factor Authentication (MFA), Geo-location verification, Intrusion Protection System (IPS), and secure traffic via an encrypted SD-WAN which includes a built-in Intrusion Prevention System.</Themed.p>
          </Stack>
        </Stack>
      </SecSection>

      {/* Virtual Desktop */}
      <SecSection>
        <Box mb={3}>
          <Stack gap={2}>
            <Stack gap={1}>
              <Eyebrow>Enhanced Endpoint Protection</Eyebrow>
              <PlatformH3>Virtual Desktop</PlatformH3>
            </Stack>
            <Themed.p>All work happens via a virtual desktop in the cloud. The infrastructure is highly redundant, with upwards of 99.99% uptime. Additional protection and segmentation includes anti screen capture, anti key logging, 26+ cloud AV scans, as well as disabling removable media, screen captures, and cut/paste functionality to secure and restrict data movement from the virtual workspace to an endpoint device.</Themed.p>
          </Stack>
        </Box>
      </SecSection>

      {/* Biometric Auth */}
      <SecSection>
        <Box mb={3}>
          <Stack gap={2}>
            <Stack gap={1}>
              <Eyebrow>Enhanced Agent Security</Eyebrow>
              <PlatformH3>Biometric Authentication</PlatformH3>
            </Stack>
            <Themed.p>Ensure the Mod behind the device is who they say they are. Non-invasive biometric monitoring detects changes in agent behavior, prevents further action, and requests additional authentication if it thinks the Mod has changed.</Themed.p>
          </Stack>
        </Box>
      </SecSection>


      {/* Security Standards */}
      <div sx={{
          display: ['block', 'block', 'flex'], 
          // mt: [null, 5, null, null] 
          }}>
        <ShieldCol>
          &nbsp;
        </ShieldCol>
        <div sx={{
            maxWidth: 'col6',
            mx: ['auto', 'auto', 0]
        }}>
          <Box mb={3}>
            <Stack gap={3}>
              <Stack gap={1}>
                <Eyebrow>Compliant</Eyebrow>
                <PlatformH3>Security Standards</PlatformH3>
              </Stack>
              <Stack gap="3">
                <SecStandard>SOC2 Type 2</SecStandard>
                <SecStandard>HIPAA</SecStandard>
                <SecStandard>PCI-DSS</SecStandard>
              </Stack>
            </Stack>
          </Box>
        </div>
      </div>


      <div sx={{ display: ['block', 'block', 'flex'] }}>
        <ShieldCol>&nbsp;</ShieldCol>
        <CopyCol>
          <hr
            sx={{
              mt: 4,
              height: 4,
              bg: "text",
            }}
          />
          <Themed.p
            sx={{
              fontStyle: "italic",
              mt: 3,
            }}
          >
            We also offer enhanced physical security paired with Cubeless. Ask us about our operations centers.
          </Themed.p>
        </CopyCol>
      </div>
    </Stack>

    <hr sx={{ height: 16, bg: "text", mt: 5, mb: 5 }} />
  </div>
)
